<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img width="40" :src="appLogoImage" alt="logo" />
        <h2 class="brand-text text-primary ml-1" style="margin-top: 8px">
          {{ appName }}
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login" style="width: 80%" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto sidebar-content">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            TUSBuddy'ye Hoş Geldiniz 👋
          </b-card-title>

          <!-- form -->
          <b-form class="auth-login-form mt-4" @submit.prevent>
            <div v-if="!loginCodeSent">
              <b-form-group
                class="mt-1"
                label="Telefon Numaranız"
                label-for="register-phone"
              >
                <b-input-group>
                  <b-input-group-prepend is-text> (+90) </b-input-group-prepend>
                  <b-form-input
                    id="register-phone"
                    v-model="phone"
                    placeholder=""
                    :formatter="phoneFormatter"
                  />
                </b-input-group>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group class="mt-2">
                <div class="d-flex justify-content-between">
                  <label for="login-password">TUSBuddy Şifreniz</label>
                  <b-link :to="{ name: 'sifremi-unuttum' }">
                    <small style="font-weight: 500; text-decoration: underline"
                      >Şifreni mi unuttun?</small
                    >
                  </b-link>
                </div>
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="login-password"
                    v-model="password"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
            <div v-else>
              <b-alert variant="success" show>
                <h4 class="alert-heading">Giriş Kodu Gönderildi</h4>
                <div class="alert-body">
                  Telefonunuza son gönderilen SMS kodunu girerek giriş
                  yapabilirsiniz.
                </div>
              </b-alert>

              <b-form-group
                class="mt-4"
                label="SMS Kodu"
                label-for="login-code"
              >
                <b-form-input
                  id="login-code"
                  v-model="loginCode"
                  placeholder="000-000"
                  :formatter="codeFormatter"
                />
              </b-form-group>
            </div>

            <!-- submit buttons -->
            <b-button
              v-if="loginCodeSent"
              type="submit"
              variant="primary"
              class="mt-4"
              block
              @click="login()"
            >
              Giriş Yap
            </b-button>
            <b-button
              v-else
              type="submit"
              variant="primary"
              class="mt-4"
              block
              @click="sendLoginCode()"
            >
              Giriş Kodu Gönder
            </b-button>
          </b-form>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BInputGroupPrepend,
  BAlert,
} from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import MobileAppLinks from "@core/layouts/components/MobileAppLinks.vue";
import { $themeConfig } from "@themeConfig";

export default {
  components: {
    MobileAppLinks,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BInputGroupPrepend,
    BAlert,
  },
  mixins: [togglePasswordVisibility],
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app;
    return {
      appName,
      appLogoImage,
    };
  },
  data() {
    return {
      phone: "",
      password: "",
      loginCodeSent: false,
      loginCode: "",
      sideImg: require("@/assets/images/pages/login.svg"),
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },

    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },

    adminObject() {
      return JSON.parse(localStorage.getItem("adminObject"));
    },
  },

  methods: {
    isLoggedIn() {
      return this.adminObject != null;
    },

    showError(errorMessage) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: errorMessage,
          icon: "AlertCircleIcon",
          variant: "danger",
        },
      });
    },

    async login() {
      var phone = this.phone;
      if (!this.loginCode) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Lütfen telefonunuza gönderilen son kodu girin",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
        return;
      }

      phone = "0" + phone;

      const payload = {
        phone: phone,
        loginCode: this.loginCode,
        showError: this.showError,
      };
      await store.dispatch("auth/login", payload);
    },

    async sendLoginCode() {
      var phone = this.phone;
      if (!phone || !this.password) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Lütfen telefon numaranızı ve şifrenizi girin",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
        return;
      }
      if (
        phone.trim().length != 13 ||
        this.isInt(phone.split(" ").join("")) == false ||
        phone.startsWith("5") == false
      ) {
        this.showError("Telefon numarası formatı hatalı!");
        return;
      }

      phone = "0" + phone;

      var available = await store.dispatch("auth/isPhoneAvailable", phone);
      if (!available) {
        this.showError("Telefon numarası kayıtlı değil!");
        return;
      }
      const payload = {
        phone: phone,
        password: this.password,
        showError: this.showError,
      };
      var sent = await store.dispatch("auth/sendLoginCode", payload);

      if (!sent) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Giriş kodu gönderilemedi!",
            text: "Girdiğiniz bilgileri kontrol edin.",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
      } else {
        this.loginCodeSent = true;

        setTimeout(() => {
          this.$refs["login-code"].focus();
          this.$refs["login-code"].select();
        }, 1000); // 1 saniye = 1000 milisaniye
      }
    },

    phoneFormatter: function (newValue) {
      newValue = newValue.trim();
      if (newValue.startsWith("5") == false) {
        newValue = newValue.substring(1, newValue.length);
      }
      var newPhoneValue = "";
      for (var i = 0; i < newValue.length && i < 13; i++) {
        if (
          newPhoneValue.length == 3 ||
          newPhoneValue.length == 7 ||
          newPhoneValue.length == 10
        ) {
          newPhoneValue = newPhoneValue + " ";
        }
        var character = newValue[i];
        if (this.isInt(character)) {
          newPhoneValue = newPhoneValue + character;
        }
      }
      this.phone = newPhoneValue;
      return this.phone;
    },

    codeFormatter: function (newValue) {
      newValue = newValue.trim(); // Boşlukları temizle
      var formattedCode = "";
      // En fazla 6 rakam ve 1 tire olacak şekilde döngü kur
      for (var i = 0; i < newValue.length && i < 7; i++) {
        var character = newValue[i];

        // Yalnızca rakam olduğunda işlem yap
        if (this.isInt(character)) {
          if (formattedCode.length === 3) {
            // Üç rakamdan sonra tire ekle
            formattedCode += "-";
          }
          // Rakamı ekle
          formattedCode += character;
        }
      }
      this.code = formattedCode;
      return this.code;
    },

    isInt(value) {
      return (
        !isNaN(value) &&
        parseInt(Number(value)) == value &&
        !isNaN(parseInt(value, 10)) &&
        !(value + "").includes(".") &&
        !(value + "").includes(",")
      );
    },
  },

  created() {},

  beforeMount() {
    if (this.isLoggedIn()) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Zaten giriş yaptınız!",
          icon: "AlertCircleIcon",
          variant: "warning",
        },
      });
      var router = this.$router;
      setTimeout(function () {
        router.push("/").catch(() => {});
      }, 1000);
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

@media (max-width: 992px) {
  .sidebar-content {
    padding-top: 70px;
  }
}
</style>
